<template>
    <!-- <div style="background-color:#fffde5"> -->
    <div class="pb-4 body">
        <nav class="navbar pt-2 pb-2 pl-5 pr-5 sticky-top navbar-expand-lg navbar-light text-light sbg-blue">
        <img width="150" src="../assets/logoussnewnotext.png"/>
        <ul class="navbar-nav ml-auto" style="margin-top:-3px">
            <li class="nav-item">
                <a class="sbn-navbar-active" @click="toPage('/homepage')">Beranda</a>
            </li>
            <li class="nav-item">
                <a class="sbn-navbar" @click="toPage('/transaction')">Status Pembelian</a>
            </li>
            <li class="nav-item">
                <a class="sbn-navbar" @click="toPage('/administration')">Hasil Try Out</a>
            </li>
            <li class="nav-item dropdown">
                <a style="text-transform: capitalize;" class="sbn-navbar-invert dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Hai, {{display.fullName}}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" style="cursor:pointer;color:#3B73C5;font-size:14px" @click="toPage('/profile')">Profil</a>
                <a class="dropdown-item" style="cursor:pointer;color:#3B73C5;font-size:14px" @click="toPage('/');removeAnyStoredInformation()">Keluar</a>
                </div>
            </li>
        </ul>
        </nav>
        
        <div style="height:470px" :style="{ backgroundImage: 'url(' + require('@/assets/header2.jpg') + ')' }">
            <div class="container">
                <div class="row">
                    <div class="col-6">
                    </div>
                    <div class="col-6" style="margin-top:40px">
                        <div class="card p-5 srd-25" style="border:none;box-shadow: 5px 5px 5px -2px #bbbbbb;">
                            <p class="st-blue-title mb-5" style="text-align:center">Timeline Try Out #1</p>
                            <p style="font-size:15px;font-weight:bold;text-align:left">Pendaftaran TO : 7 - 13 Desember 2020</p>
                            <p style="font-size:15px;font-weight:bold;text-align:left">Pengerjaan TO : 15 - 19 Desember 2020</p>
                            <p style="font-size:15px;font-weight:bold;text-align:left">Pengumuman Nilai TO : 20 Desember 2020</p>
                            <p style="font-size:15px;font-weight:bold;text-align:left">Pembahasan Live Class : 23 Desember 2020</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top:-40px">
        <div class="container">
            <div class="row">
                <div class="col-sm" v-for="d in packageData" :key="d.packageID">
                    <div class="card srd-25" style="border:none;box-shadow: 5px 5px 5px -2px #bbbbbb;">
                        <div class="row pl-4 pt-4 pr-4 mt-4 ">
                            <div class="col-sm">
                            <img v-show="d.packageID==1||d.packageID==3"  src="../assets/saintek.png" 
                            class="card-img-top" alt="..." style="width:150px;">
                            <img v-show="d.packageID==2||d.packageID==4" src="../assets/soshum.png" 
                            class="card-img-top" alt="..." style="width:150px;">
                            </div>
                            <div class="col-sm">
                            </div>
                        </div>
                        <div class="card-body text-left p-5">
                            <p class="st-blue-title">{{d.packageDescription}}</p>
                            <p style="font-size:15px;" class="card-text mb-4">Cocok buat kamu yang akan menghadapi UTBK</p>
                            <hr/>
                            <p class="st-darkblue-title">Rp {{d.packagePriceDisplay}},-</p>
                        <button v-show="d.packageActive==1" @click="orderPackage(d.packageID)" class="btn sbtn-orange p-2" type="submit" style="width:100%;font-size:15px;font-weight:bold">Beli Paket</button>
                        <button v-show="d.packageActive==0" class="btn sbtn-orange p-2" type="submit" style="border-radius:10px;width:100%;font-size:15px;font-weight:bold">Paket dapat mulai dipesan saat periode pengerjaan TO</button>
                        </div>
                    </div>
                </div>
             
            </div>
        </div>

        </div>
        <b-modal
            id="modal"
            ref="modal"
            title="Pemesanan Paket"
            hide-footer
            centered
            >
            <span v-show="existedUserPackage>0" >
                <span v-for="d in packageSelectedData" :key="d.packageID+100000">
                    Kamu tidak dapat melakukan pemesanan atas paket <b class="stbg-orange st-white">{{d.packageDescription}}</b><br/>
                    Karena masih terdapat <b>{{existedUserPackage}} Paket Aktif</b> pada akun kamu<br/><br/>
                    Silahkan kembali melanjutkan TO dengan paket yang sudah ada, melalui menu <b style="cursor:pointer" @click="toPage('/transaction')" class="stbg-blue st-white">Status Pembelian</b> bagian <b class=" stbg-orange st-white">Siap TO</b>
                </span>
            </span>
            <span v-show="existedUserPackage==0">
                <span v-show="orderPhase==1&&orderStyle=='USERVOUCHER'" style="font-size:14px" v-for="d in packageSelectedData" :key="d.packageID+1000">
                    <b-row class="mb-2">
                        <b-col sm="3">
                            Kode
                        </b-col>
                        <b-col sm="9">
                            {{d.packageCode}}
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col sm="3">
                            Deskripsi
                        </b-col>
                        <b-col sm="9">
                            {{d.packageDescription}}
                        </b-col>
                    </b-row>
                    <b-row v-show="totalProgramInput==1" class="mb-2">
                        <b-col sm="3">
                            Harga
                        </b-col>
                        <b-col sm="9">
                            Rp{{d.packagePriceDisplay}},-/1 Pilihan Prodi
                        </b-col>
                    </b-row>
                    <b-row class="mb-2" align-v="center">
                        <b-col sm="12">
                            <b>Terdapat Kode Voucher yang sudah kamu daftarkan!<br/>
                            Kamu tidak perlu melakukan pembayaran & bisa langsung mulai Try Out!</b>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col style="text-align:right">
                            <b-button @click="confirmOrderPackage(d.packageID,'PAID')" variant="primary" size="sm">Pesan Paket</b-button>
                        </b-col>
                    </b-row>
                </span>
                <span style="font-size:14px" v-show="orderPhase==2&&orderStyle=='USERVOUCHER'" v-for="d in packageSelectedData" :key="d.packageID+100+10000">
                    Paket telah berhasil dipesan menggunakan Kode Voucher/Promo Kamu

                    <b>Keterangan :</b><br/>
                    1. Try Out dapat langsung dimulai menggunakan tombol "Langsung Mulai Try Out" pada Pop Up berikut.<br/>
                    2. Apabila Try Out tidak ingin langsung dikerjakan, kamu dapat mulai Try Out nanti melalui Menu "Status Pembelian" Tab "Siap TO"
                    <br/>
                    <br/>
                    <b-row>
                        <b-col sm="5">
                        </b-col>
                        <b-col sm="2"></b-col>
                        <b-col sm="5">
                            <b-button block variant="outline-success" @click="startExam(d.packageID, selectedUserPackageID)" size="sm">Langsung Mulai Try Out</b-button>
                        </b-col>
                    </b-row>
                </span>
                <span v-show="orderPhase==1&&orderStyle=='DEFAULT'" style="font-size:14px" v-for="d in packageSelectedData" :key="d.packageID">
                    <b-row class="mb-2">
                        <b-col sm="3">
                            Kode
                        </b-col>
                        <b-col sm="9">
                            {{d.packageCode}}
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col sm="3">
                            Deskripsi
                        </b-col>
                        <b-col sm="9">
                            {{d.packageDescription}}
                        </b-col>
                    </b-row>
                    <b-row v-show="totalProgramInput==1" class="mb-2">
                        <b-col sm="3">
                            Harga
                        </b-col>
                        <b-col sm="9">
                            Rp{{d.packagePriceDisplay}},-/1 Pilihan Prodi
                        </b-col>
                    </b-row>
                    <b-row v-show="totalProgramInput==1" class="mb-2" align-v="center">
                        <b-col  sm="3">
                            Pilihan Prodi
                        </b-col>
                        <b-col sm="9">
                            <b-form-input size="sm" v-model="totalProgram" v-on:input="getTotalPrice(d.packagePrice)" type="text"></b-form-input>
                            
                        </b-col>
                    </b-row> 
                    <b-row class="mb-2">
                        <b-col sm="3">
                            Total Harga
                        </b-col>
                        <b-col sm="9">
                            Rp{{packageSelectedTotalPrice}},-
                        </b-col>
                    </b-row>
                    <b-row class="mb-2" align-v="center">
                        <b-col sm="12">
                            <b>Untuk sementara pemesanan hanya dapat dilakukan jika kamu memiliki Kode Promo atau sudah mendaftarkan Kode Voucher pada Profil kamu</b>
                        </b-col>
                    </b-row>
                    <b-row  class="mb-2" align-v="center">
                        <b-col  sm="3">
                            Kode Promo
                        </b-col>
                        <b-col sm="9">
                            <b-form-input  size="sm" v-model="promoCode" type="text"></b-form-input>
                            
                        </b-col>
                    </b-row> 
                    <b-row>
                        <b-col style="text-align:right">
                            <b-button @click="validateOrderViaPromo(d.packageID,'PAID')" variant="primary" size="sm">Pesan Paket</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12">
                            <b>{{promoMessage}}</b>
                        </b-col>
                    </b-row>
                    <!-- <b-row class="mb-2" align-v="center">
                        <b-col sm="3">
                            Bayar Via
                        </b-col>
                        <b-col sm="9">
                            <b-form-select size="sm" v-model="selectedPaymentOption" :options="paymentOptions"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col style="text-align:right">
                            <b-button variant="primary" size="sm">Pesan</b-button>
                        </b-col>
                    </b-row> -->
                </span>
                <span style="font-size:14px" v-show="orderPhase==2&&orderStyle=='DEFAULT'" v-for="d in packageSelectedData" :key="d.packageID+100">
                    Terimakasih Anda sudah melakukan pemesanan atas <b>Paket {{d.packageDescription}}</b> 
                    dengan metode pembayaran <b>{{selectedPaymentOption}}.</b><br/>
                    
                    <div v-show="selectedPaymentOption == 'BANKTRANSFER'">
                        <img width="100" src="../assets/banktransfer.png"/><br/>
                        Untuk menyelesaikan pembayaran, Anda dapat melakukan Transfer ke Rekening berikut :<br/>
                        <b>Nama : Salemba Group</b><br/>
                        <b>No Rek : 1250012826236</b><br/><br/>
                    </div>
                    
                    <div v-show="selectedPaymentOption == 'GOPAY'">
                        <img width="100" src="../assets/gopay.jpg"/><br/>
                        Untuk menyelesaikan pembayaran, Anda dapat melakukan Top Up ke Saldo Gopay berikut :<br/>
                        <b>Nama : Salemba Group</b><br/>
                        <b>Nomor : 0821-1236-6956</b><br/><br/>
                    </div>
                    
                    <div v-show="selectedPaymentOption == 'OVO'">
                        <img width="100" src="../assets/ovo.png"/><br/>
                        Untuk menyelesaikan pembayaran, Anda dapat melakukan Top Up ke Saldo OVO berikut :<br/>
                        <b>Nama : Salemba Group</b><br/>
                        <b>Nomor : 0821-1236-6956</b><br/><br/>
                    </div>

                    <b>Keterangan :</b><br/>
                    1. Maksimal Pembayaran berlaku 1x24 jam setelah pemesanan paket dilakukan.<br/>
                    2. Daftar Pesanan dapat Anda cek melalui Menu Transaction.
                    <br/>
                    <br/>
                    <b-row>
                        <b-col sm="5">
                        </b-col>
                        <b-col sm="2"></b-col>
                        <b-col sm="5">
                            <b-button block variant="outline-success" @click="toPage('transaction')" size="sm">Cek Daftar Pesanan</b-button>
                        </b-col>
                    </b-row>
                </span>
            </span>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data(){
        return {
            orderedPackageID: 0,
            orderPhase: 1,
            orderStyle: null,
            totalProgram: 1,
            promoCode: null,
            promoMessage: null,
            totalProgramInput: 0,
            userData: [],
            packageData: [],
            selectedPackageID: 0,
            selectedUserPackageID: null,
            packageSelectedData: [],
            packageSelectedTotalPrice: 0,
            packageTableFilter: [
                {
                    key: 'packageOrder', label: 'No.', sortable: false
                },
                {
                    key: 'packageCode', label: 'Kode', sortable: false
                },
                {
                    key: 'packageDescription', label: 'Deskripsi', sortable: false
                },
                {
                    key: 'packagePriceDisplay', label: 'Harga', sortable: true
                },
                {
                    key: 'orderButton', label: '', sortable: false
                }
            ],
            paymentOptions: [
                { value: null, text: 'Pilih Metode Pembayaran' },
                { value: 'BANKTRANSFER', text: 'Bank Transfer' },
                { value: 'GOPAY', text: 'Gopay' },
                { value: 'OVO', text: 'Ovo' }
            ],
            selectedPaymentOption: null,
            display: {
                fullName: ''
            },
            existedUserPackage: null
        }
    },
    afterCreate() {
        let userID = localStorage.getItem("userID");
        if(localStorage.getItem("userID") == null){
            this.$router.push("/");
        }
    },
    beforeCreate() {
        let userID = localStorage.getItem("userID");
        axios
        .get("https://backend-development.uss-utbk.com/package")
        .then(res => {
            this.packageData = res.data.values;
        });
        axios
        .get("https://backend-development.uss-utbk.com/userinformation",{
            params: {
                userID: userID
            }
        })
        .then(res => {
            this.userData = res.data.values;
            let userData = res.data.values;
            this.display.fullName = userData[0].userFullName;
        });
    },
    methods:{
        validateOrderViaPromo(packageID,orderStatus){
            let ref = this;
            axios
            .get("https://backend-development.uss-utbk.com/n-promobypromocode",{
                params: {
                    promoCode: ref.promoCode
                }
            })
            .then(res => {
                let data
                try {
                    data = res.data.values[0];
                    console.log(data.promoCode);
                    ref.promoMessage = 'Kode Promo Valid'
                    ref.orderStyle = 'USERVOUCHER'
                    axios.put('https://backend-development.uss-utbk.com/n-updatepromotimebypromocode', {
                        promoCode: ref.promoCode
                    })
                    .then(function (response) {
                        ref.output = response.data;

                    })
                    .catch(function (error) {
                        ref.output = error;
                    });
                    ref.confirmOrderPackage(packageID,orderStatus);
                }
                catch(error){
                    console.log('Goblok');
                    ref.promoMessage = 'Kode Promo tidak Valid'
                }

            });
        },
        startExam(packageID,userPackageID){
            localStorage.setItem("packageID", packageID);
            localStorage.setItem("userPackageID", userPackageID);
            this.toPage('exam');

        },
        confirmOrderPackage(packageID,orderStatus){
            this.orderedPackageID = packageID;
            if(this.orderedPackageID!=0){
                let object = this;
                let userID = parseInt(localStorage.getItem("userID"));
                let userPackagePaymentOption = this.selectedPaymentOption;
                let userPackageTotalStudyProgram = this.totalProgram;
                axios.post('https://backend-development.uss-utbk.com/userpackage', {
                    userID: userID,
                    userPackageStatus: orderStatus,
                    userPackageTotalStudyProgram: userPackageTotalStudyProgram,
                    userPackagePaymentOption: userPackagePaymentOption,
                    packageID: packageID
                })
                .then(res => {
                    let data = res.data.values;
                    console.log(data);
                    object.selectedUserPackageID = data;

                });
                this.orderedPackageID = 0;
                this.orderPhase = 2;
            }
            else {
                this.orderedPackageID = 0;
                console.log('Tidak Pesan');
                
            }
        },
        removeAnyStoredInformation(){
            localStorage.removeItem("userID");
            localStorage.removeItem("userPackageID");
            localStorage.removeItem("packageID");
        },
        toPage(param){
            this.$router.push(param);
        },
        orderPackage(param){
            let ref = this;
            let userID = localStorage.getItem("userID");
            axios
            .get("https://backend-development.uss-utbk.com/userpackage",{
                params: {
                    userID: userID
                }
            })
            .then(res => {
                let data = res.data.values;
                let dataManipulated = [];
                for(let i = 0; i < data.length;i++){
                    if(data[i].packageID==param&&data[i].userPackageStatus=='PAID'
                    //|| data[i].packageID==param&&data[i].userPackageStatus=='UNPAID'
                    ){
                        dataManipulated.push(data[i]);
                    }
                }
                ref.existedUserPackage = dataManipulated.length;
            });
            axios
            .get("https://backend-development.uss-utbk.com/userinformation",{
                params: {
                    userID: userID
                }
            })
            .then(res => {
                let userData = res.data.values[0];
                let voucherFlag = userData.userHasVoucher;
                if(voucherFlag==1){
                    ref.orderStyle = 'USERVOUCHER'
                }
                else {
                    ref.orderStyle = 'DEFAULT'
                }
            });
            axios
            .get("https://backend-development.uss-utbk.com/packagebypackageid",{
                params: {
                    packageID: param
                }
            })
            .then(res => {
                this.packageSelectedData = res.data.values;
                let letPackageSelectedData = res.data.values;
                this.orderPhase = 1;
                this.totalProgram = 1;
                this.selectedPaymentOption = null;
                this.packageSelectedTotalPrice = letPackageSelectedData[0].packagePrice;
                this.openModal();
            });
        },
        openModal(){
            this.$refs['modal'].show()
        },
        closeModal(){
            this.$refs['modal'].hide()
        },
        formatNumber(param) {
            return param.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        getTotalPrice(param){
            this.packageSelectedTotalPrice = this.formatNumber(this.totalProgram * param);
        }
    }
}
</script>

<style scoped>

.body {
  background-color: #F3F7FA;
}

.sbg-blue {
  background-color: #3B73C5;
}
.stbg-blue {
  background-color: #3B73C5;
  transition:0.2s;
  padding:2px 6px;
}
.stbg-blue:hover {
  background-color: #733FC0;
  transition:0.2s;
}

.stbg-orange {
  background-color: #FF7600;
  padding:2px 6px;
}
.sbg-purple {
  background-color: #733FC0;
}
.sbg-orange {
  background-color: #FF7600;
}
.sbtn-orange {
  background-color: #FF7600;
  color: #FFFFFF;
}
.sbtn-orange:hover {
  background-color: #ff4c4c;
  color: #FFFFFF;
}
.st-purple {
  color: #733FC0;
}
.st-blue {
  color: #3B73C5;
}
.st-blue-title {
  color: #3B73C5;
  font-size:20px;
  font-weight: bold;
}
.st-darkblue-title {
  color: #1D3962;
  font-size:35px;
  font-weight: bold;
}
.st-blue-title-30 {
  color: #3B73C5;
  font-size:30px;
  font-weight: bold;
}
.st-blue-title-25 {
  color: #3B73C5;
  font-size:30px;
  font-weight: bold;
}
.st-white {
  color: #FFFFFF;
}
.st-gray {
  color: #c9c9c9;
}
.st-darkblue {
  color: #1D3962;
}
.srd-25 {
  border-radius: 25px;
}
.sbn-navbar {
    color: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin-left:8px;
    font-weight: bold;
    cursor: pointer;
}
.sbn-navbar-active {
    color: #3B73C5;
    background-color: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin-left:8px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}
.sbn-navbar-invert {
    color: #3B73C5;
    background-color: #FFF;
    font-size: 14px;
    padding: 10px;
    margin-left:30px;
    font-weight: bold;
    cursor: pointer;
}
.sbn-navbar:hover {
    background-color: #FFF;
    color: #3B73C5;
    padding: 10px;
    border-radius: 10px;
    margin-left:8px;
    text-decoration: none;
}
.sbn-navbar-invert:hover {
    color: #3B73C5;
    background-color: #FFF;
    padding: 10px;
    font-weight: bold;
    text-decoration: none;
}
</style>