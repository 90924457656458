<template>
    <div>
        <nav class="navbar pt-2 pb-2 pl-5 pr-5 sticky-top navbar-expand-lg navbar-light text-light sbg-blue">
        <img width="150" src="../assets/logoussnewnotext.png"/>
        <ul class="navbar-nav ml-auto" style="margin-top:-3px">
            <li class="nav-item">
                <a class="sbn-navbar" @click="toPage('/homepage')">Beranda</a>
            </li>
            <li class="nav-item">
                <a class="sbn-navbar" @click="toPage('/transaction')">Status Pembelian</a>
            </li>
            <li class="nav-item">
                <a class="sbn-navbar" @click="toPage('/administration')">Hasil Try Out</a>
            </li>
            <li class="nav-item">
                <a class="sbn-navbar-active">Try Out</a>
            </li>
            <li class="nav-item">
            <li class="nav-item dropdown">
                <a style="text-transform: capitalize;" class="sbn-navbar-invert dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Hai, {{display.fullName}}
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a class="dropdown-item" style="cursor:pointer;color:#3B73C5;font-size:14px" @click="toPage('/profile')">Profil</a>
                <!-- <a class="dropdown-item" style="cursor:pointer;color:#3B73C5;font-size:14px" @click="toPage('/');removeAnyStoredInformation()">Keluar</a> -->
                </div>
            </li>
        </ul>
        </nav>
        <div class="pl-5 pr-5">
            <div class="card st-blue mt-5  srd-25 sbg-white" style="background-color#FFF;border:none;">
                
                <div class="sfs-15 sbg-white" v-show="taskSubmitLoad==0">
                    <div v-show="taskStarted==0&&taskFinished==1">
                        <div class="card srd-25 p-4 sbg-white" >
                            <button class="sbtn-blue">Informasi</button>
                            <div class="par-padding">
                                <p style="text-align:left;color:#000">Kamu telah menyelesaikan seluruh grup TO pada paket ini!</p>
                                <p style="text-align:left;color:#000">Hasilnya dapat kamu lihat pada Tab <b>Hasil Try Out</b>!</p>
                                <p style="text-align:left;color:#000">Tetap semangat ya pejuang PTN!</p>
                            </div>
                        </div>
                    </div>
                    <div class="card srd-25 p-4 sbg-white" v-show="taskStarted==0&&taskFinished==0">
                        <b-tabs v-model="tabIndex" class="nav-pills sbg-white">
                            <b-tab lazy class=" sbg-white" v-for="d in examGroupSubPackageCombined" :key="d.subPackageExamGroupID">
                            <template v-slot:title>
                                {{d.subPackageExamGroupDesc}}
                            </template>
                                <b-table class="stbl sbg-white" style="font-size:25px;" borderless :items="d.subPackageList" 
                                :fields="filterSubPackageList">
                                    <template v-slot:cell(subPackageTime)="data">
                                        {{getFormatTime(data.item.subPackageTime)}} Menit
                                    </template>
                                    <template v-slot:cell(subPackageTotalQuestion)="data">
                                        {{data.item.subPackageTotalQuestion}} Soal
                                    </template>
                                </b-table>
                                <span style="float:right" v-show="(d.subPackageExamGroupID-d.userPackageCompletedSubPackageGroup)<1" class="sbtn-disabled">Grup TO {{d.subPackageExamGroupDesc}} Telah Diselesaikan</span>
                                <div v-show="taskInLoad==0">
                                <button style="float:right"  v-show="(d.subPackageExamGroupID-d.userPackageCompletedSubPackageGroup)==1" 
                                @click="startTryOut(
                                    d.subPackageExamGroupID,
                                    d.subPackageExamGroupDesc,
                                    d.userPackageCompletedSubPackageGroup,
                                    d.subPackageTotalExamGroup)" class="sbtn-blue">Mulai Grup TO {{d.subPackageExamGroupDesc}}
                                    </button>
                                </div>
                                <div v-show="taskInLoad==1">
                                <button style="float:right"  v-show="(d.subPackageExamGroupID-d.userPackageCompletedSubPackageGroup)==1" 
                                class="sbtn-blue">Try Out {{d.subPackageExamGroupDesc}} akan segera dimulai
                                    </button>
                                </div>
                                <span style="float:right" v-show="(d.subPackageExamGroupID-d.userPackageCompletedSubPackageGroup)>1" class="sbtn-disabled">Selesaikan Grup TO Potensi Skolastik Terlebih Dahulu</span>
                            </b-tab>
                        </b-tabs>   
                    </div>
                    <div v-show="taskStarted==1&&taskFinished==0">
                        <div class="row st-black sfs-14" style="text-align:left;">
                            <div class="col-8">
                                <div class="card srd-25 p-4">
                                    <button class="sbtn-blue">{{selectedExamGroupDesc}}</button>
                                    <span v-show="s.subPackageOrder==subPackageOrderSelected" v-for="s in subPackageQuestionAnswerGeneratedList" :key="s.subPackageOrder">
                                        <div v-for="(q,i) in s.questionList" v-bind:key="q.questionOrder">
                                            <div class="mt-4" v-show="i === activeQuestion">
                                            <div class="mb-2">
                                                <span v-html="q.questionName"></span>
                                            </div>
                                                        <label style="cursor:pointer;display:block;border:1px solid #000;border-radius:5px" class="p-2" v-for="a in q.answerList" v-bind:key="a.answerOrder">
                                                                
                                                                <input type="radio"
                                                                    v-bind:value="userID+';'+userPackageID+';'+q.questionID+';'+q.questionOrder+';'+q.perSubPackageQuestionOrder+';'+a.answerID+';'+a.answerIsRight+';'+a.answerAlphabet+';'+'0'"
                                                                    v-bind:name="q.questionOrder-1"
                                                                    v-model="subPackageQuestionAnsweredList[q.perSubPackageQuestionOrder-1]"
                                                                    /> <span v-html="a.answerName"></span>
                                                            
                                                        </label>
                                            </div>
                                        </div>
                                    </span>
                                    <div v-show="subPackageOrderSelected<=subPackageQuestionAnswerGeneratedList.length" style="float:left" class="mt-3">
                                        <button v-show="activeQuestion!=0" class="sbtn-sm-blue mr-2" @click="activePrevNextQ(activeQuestion-1)">Soal Sebelumnya</button>
                                        <button v-show="activeQuestion!=(subPackageQuestionAnsweredList.length-1)" class="sbtn-sm-blue mr-2" @click="activePrevNextQ(activeQuestion+1)">Soal Berikutnya</button>
                                        <button class="sbtn-sm-yellow mr-2" @click="reviewThisQuestion()">Tandai Ragu - Ragu</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="card srd-25 p-4">
                                    <button class="sbtn-blue">Durasi</button><br/>
                                    
                                    
                                        <table class="stbl-information">
                                            <tr>
                                                <td style="width:150px;">
                                                    Sisa Waktu
                                                </td>
                                                <td>
                                                    <span v-show="s.subPackageOrder==subPackageOrderSelected" v-for="s in subPackageQuestionAnswerGeneratedList" :key="s.subPackageOrder">
                                                        {{subPackageTimeFormatted}}
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>
                                </div>
                                <div class="card srd-25 p-4 mt-4">
                                    <button class="sbtn-blue">Informasi</button><br/>
                                    <span v-show="s.subPackageOrder==subPackageOrderSelected" v-for="s in subPackageQuestionAnswerGeneratedList" :key="s.subPackageOrder">
                                        
                                        <table class="stbl-information">
                                            <tr>
                                                <td style="width:150px;">
                                                    Sub Test
                                                </td>
                                                <td>
                                                    {{s.subPackageName}}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Jumlah Soal
                                                </td>
                                                <td>
                                                    20
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Waktu Pengerjaan
                                                </td>
                                                <td>
                                                    {{getFormatTime(s.subPackageTime)}} Menit
                                                </td>
                                            </tr>
                                        </table>
                                    </span>
                                </div>
                                <div class="card srd-25 p-4 mt-4">
                                    <button class="sbtn-blue">Status Jawaban</button><br/>
                                    <span style="margin-top:20px;">
                                        <span  v-for="q in subPackageQuestionAnsweredList" :key="q">
                                            
                                            <div @click="changeActiveQ(q)" style="cursor:pointer;float:left;margin-right:10px;margin-bottom:50px">
                                                <a style="font-size:12px; color:#3B73C5; padding:10px 14px;border:1px solid #3B73C5;border-radius:10px;" >{{q.split(";")[7]}}</a>
                                                <div v-bind:class="q.split(';')[8] == '1' ? snavReview : q.split(';')[5] != '-' && q.split(';')[4]-1 != activeQuestion ? snavAnswered : q.split(';')[4]-1 == activeQuestion ? snavActive : snavDefault" style="position:relative;margin-top:-40px;margin-left:25px;
                                                font-size:10px;text-align:center;line-height:25px;width:25px;height:25px;border-radius:50%;">
                                                    {{q.split(";")[3]}}
                                                </div>
                                            </div>
                                        </span>
                                    </span>
                                    <!-- <b-button  @click="nextPackage()" class="text-left" block variant="outline-primary" size="sm" inactive>Sub Test Berikutnya</b-button> -->
                                    <button v-show="subPackageOrderSelected<subPackageQuestionAnswerGeneratedList.length" class="sbtn-sm-blue mr-2" @click="nextSubPackageConfirmation();">Sub Test Berikutnya >>></button>
                                    <button v-show="subPackageOrderSelected==subPackageQuestionAnswerGeneratedList.length" class="sbtn-sm-blue mr-2" @click="nextSubPackageConfirmation();">Submit Test</button>    
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal
            id="modal"
            ref="modal"
            hide-header
            hide-footer
            centered
            size="lg"
            >
            <div v-show="generatedExamGroupID==generatedTotalExamGroup && subPackageOrderSelected==subPackageRawList.length">
                <div class="row">
                    <div class="col-8"> 
                        <button style="width:100%" class="sbtn-blue">Summary {{subPackageNameSelected}}</button><br/>
                        <table class="stbl-information-modal mt-2">
                            <tr>
                                <td colspan="2">
                                    <b>Dari {{subTestSummary[0].length}} Soal pada Sub Test {{subPackageNameSelected}}, 
                                    berikut rincian tes kamu</b>
                                </td>
                            </tr>
                            <tr>
                                <td style="width:200px;">
                                    Terjawab
                                </td>
                                <td>
                                    {{subTestSummary[1].length}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Terjawab (Ragu - Ragu)
                                </td>
                                <td>
                                    {{subTestSummary[3].length}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Tidak Terjawab
                                </td>
                                <td>
                                    {{subTestSummary[2].length}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Tidak Terjawab (Ragu - Ragu)
                                </td>
                                <td>
                                    {{subTestSummary[4].length}}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-4"> 
                        <button style="width:100%" class="sbtn-bordered-blue mb-3" @click="nextPackage('SUBMIT')">OK, Submit Test</button><br/>
                        <button style="width:100%" class="sbtn-bordered-orange" @click="closeModal()">Saya ingin kembali mengecek jawaban Saya</button><br/>
                    </div>
                </div>
            </div>
            <div v-show="generatedExamGroupID<generatedTotalExamGroup && subPackageOrderSelected==subPackageRawList.length">
                <div class="row">
                    <div class="col-8"> 
                        <button style="width:100%" class="sbtn-blue">Summary {{subPackageNameSelected}}</button><br/>
                        <table class="stbl-information-modal mt-2">
                            <tr>
                                <td colspan="2">
                                    <b>Dari {{subTestSummary[0].length}} Soal pada Sub Test {{subPackageNameSelected}}, 
                                    berikut rincian tes kamu</b>
                                </td>
                            </tr>
                            <tr>
                                <td style="width:200px;">
                                    Terjawab
                                </td>
                                <td>
                                    {{subTestSummary[1].length}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Terjawab (Ragu - Ragu)
                                </td>
                                <td>
                                    {{subTestSummary[3].length}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Tidak Terjawab
                                </td>
                                <td>
                                    {{subTestSummary[2].length}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Tidak Terjawab (Ragu - Ragu)
                                </td>
                                <td>
                                    {{subTestSummary[4].length}}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-4"> 
                        <button style="width:100%" class="sbtn-bordered-blue mb-3" @click="nextPackage('TONEXTGROUP')">OK, Submit Test & Masuk ke Grup TO Berikutnya</button><br/>
                        <button style="width:100%" class="sbtn-bordered-orange" @click="closeModal()">Saya ingin kembali mengecek jawaban Saya</button><br/>
                    </div>
                </div>
            </div>
            <div v-show="subPackageOrderSelected<subPackageRawList.length">
                <div class="row">
                    <div class="col-8"> 
                        <button style="width:100%" class="sbtn-blue">Summary {{subPackageNameSelected}}</button><br/>
                        <table class="stbl-information-modal mt-2">
                            <tr>
                                <td colspan="2">
                                    <b>Dari {{subTestSummary[0].length}} Soal pada Sub Test {{subPackageNameSelected}}, 
                                    berikut rincian tes kamu</b>
                                </td>
                            </tr>
                            <tr>
                                <td style="width:200px;">
                                    Terjawab
                                </td>
                                <td>
                                    {{subTestSummary[1].length}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Terjawab (Ragu - Ragu)
                                </td>
                                <td>
                                    {{subTestSummary[3].length}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Tidak Terjawab
                                </td>
                                <td>
                                    {{subTestSummary[2].length}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Tidak Terjawab (Ragu - Ragu)
                                </td>
                                <td>
                                    {{subTestSummary[4].length}}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-4"> 
                        <button style="width:100%" class="sbtn-bordered-blue mb-3" @click="nextPackage()">OK, Lanjut ke Sub Test berikutnya</button><br/>
                        <button style="width:100%" class="sbtn-bordered-orange" @click="closeModal()">Saya ingin kembali mengecek jawaban Saya</button><br/>
                    </div>
                </div>
            </div>

        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
export default {
    computed: {
        getQuestionPoint(){
            let questionPoint = 0
            try {
                questionPoint = this.questionAnsweredList.reduce((acc, item) => acc + Number(item.split(";")[4]), 0);
            }
            catch(err) {
                questionPoint = 0
            }
            return questionPoint
        }
    },
    methods: {
        closeModal(){
            this.$refs['modal'].hide()
        },
        openModal(){
            this.$refs['modal'].show();
        },
        nextSubPackageConfirmation(){
            
            let combinedData = [];
            let totalQuestion = [];
            let totalNotFilled = [];
            let totalFilled = [];
            let totalNotFilledPreviewed = [];
            let totalFilledPreviewed = [];
            let object = this;
            totalQuestion = this.subPackageQuestionAnsweredList;
            for(let i = 0; i < object.subPackageQuestionAnsweredList.length; i++){
                let detailVarText = object.subPackageQuestionAnsweredList[i];
                let detailValArr = object.subPackageQuestionAnsweredList[i].split(';');
                if(detailValArr[5]!='-' && detailValArr[8]==1){
                    totalFilledPreviewed.push(detailVarText);
                }
                else if(detailValArr[5]=='-' && detailValArr[8]==1){
                    totalNotFilledPreviewed.push(detailVarText);
                }
                else if(detailValArr[5]!='-' && detailValArr[8]==0){
                    totalFilled.push(detailVarText);
                }
                else if(detailValArr[5]=='-' && detailValArr[8]==0){
                    totalNotFilled.push(detailVarText);
                }
            }
            combinedData.push(totalQuestion);
            combinedData.push(totalFilled);
            combinedData.push(totalNotFilled);
            combinedData.push(totalFilledPreviewed);
            combinedData.push(totalNotFilledPreviewed);
            this.subTestSummary = combinedData;
            this.openModal();
            
        },
        reviewThisQuestion(){
            let value = this.subPackageQuestionAnsweredList[this.activeQuestion];
            if(value.split(';')[8]=='0'){
                console.log(this.subPackageQuestionAnsweredList[this.activeQuestion]);
                this.subPackageQuestionAnsweredList[this.activeQuestion] = 
                value.split(';')[0] + ';' + value.split(';')[1] + ';' + value.split(';')[2] + ';' + value.split(';')[3] + ';' + value.split(';')[4]
                + ';' + value.split(';')[5] + ';' + value.split(';')[6] + ';' + value.split(';')[7] + ';' + '1';
                console.log('test');
                console.log(this.subPackageQuestionAnsweredList[this.activeQuestion]);
            }
            else {
                console.log(this.subPackageQuestionAnsweredList[this.activeQuestion]);
                this.subPackageQuestionAnsweredList[this.activeQuestion] = 
                value.split(';')[0] + ';' + value.split(';')[1] + ';' + value.split(';')[2] + ';' + value.split(';')[3] + ';' + value.split(';')[4]
                + ';' + value.split(';')[5] + ';' + value.split(';')[6] + ';' + value.split(';')[7] + ';' + '0';
                console.log('test-b');
                console.log(this.subPackageQuestionAnsweredList[this.activeQuestion]);
            }
        },
        startTryOut(subPackageExamGroupID, subPackageExamGroupDesc, userPackageCompletedSubPackageGroup, subPackageTotalExamGroup) {
            console.log('test');
            this.taskInLoad = 1;
            if(this.taskStarted==0){
                let userPackageID = localStorage.getItem("userPackageID");
                let examGroupID = subPackageExamGroupID;
                let completedExamGroupID = userPackageCompletedSubPackageGroup;
                let totalExamGroup = subPackageTotalExamGroup;
                this.generatedExamGroupID = examGroupID;
                this.generatedCompletedExamGroupID = completedExamGroupID;
                this.generatedTotalExamGroup = totalExamGroup;
                this.selectedExamGroupDesc = subPackageExamGroupDesc;
                axios
                .get("https://backend-development.uss-utbk.com/n-examgroupedsubpackage",{
                    params: {
                        userPackageID: userPackageID,
                        examGroupID: examGroupID
                    }
                })
                .then(res => {
                    let dataSubPackage = res.data.values;
                    this.subPackageRawList = dataSubPackage;
                    axios
                    .get("https://backend-development.uss-utbk.com/n-examgroupedquestion",{
                        params: {
                            userPackageID: userPackageID,
                            examGroupID: examGroupID
                        }
                    })
                    .then(res => {
                        let dataQuestion = res.data.values;
                        this.questionRawList = dataQuestion;
                        
                        axios
                        .get("https://backend-development.uss-utbk.com/n-examgroupedanswer",{
                            params: {
                                userPackageID: userPackageID,
                                examGroupID: examGroupID
                            }
                        })
                        .then(res => {
                            let dataAnswer = res.data.values;
                            this.answerRawList = dataAnswer;
                            this.getSubPackageQuestionAnswerGenerated();
                            this.taskFinished = 0;
                            this.subPackageOrderSelected = 1;
                            this.subPackageNameSelected = this.subPackageRawList[this.subPackageOrderSelected-1].subPackageName;
                            this.getSubPackageTimeSelected();
                            this.getTimeSelectedFormatted();
                            this.taskStarted = 1;
                            this.countDownTimer();
                            this.generateTempAnsweredList();
                            this.taskInLoad = 0;
                        });
                    });
                });
            }

        },
        generateTempAnsweredList() {
            this.subPackageQuestionAnsweredList = [];
            if(this.subPackageOrderSelected>this.subPackageQuestionAnswerGeneratedList.length){
                console.log('Stop Generating');
            }
            else {
                let test = this.subPackageQuestionAnswerGeneratedList[this.subPackageOrderSelected-1].questionList;
                for(let i = 0; i < test.length; i++){
                    let testValue = String(this.userID+';'+this.userPackageID+';'+test[i].questionID+';'+test[i].questionOrder+';'+test[i].perSubPackageQuestionOrder+';'+'-'+';'+'-'+';'+'-'+';'+'0');
                    this.subPackageQuestionAnsweredList.push(testValue);
                };
            }
        },
        getSubPackageQuestionAnswerGenerated(){
            //Start Join Question with Answer
            let questionAnswerKey = "questionID"
            this.questionRawList.sort((a, b) => {
                if (a[questionAnswerKey] < b[questionAnswerKey]) {
                    return -1;
                }
                if (a[questionAnswerKey] > b[questionAnswerKey]) {
                    return 1;
                }
                return 0;
            });

            let questionRawListMap = this.questionRawList.reduce((map, row) => {
                let key = row["questionID"]; 
                map[key] = row;
                return map;
            }, {});

            let questionAnswerMap = this.answerRawList.reduce((map, row) => { 
                let key = row["questionID"];
                if (map[key]) { 
                    if (!map[key].answerList) map[key].answerList = [];
                    map[key].answerList.push(row);
                }
                return map;
            }, questionRawListMap)

            let questionAnswerGenerated = Object.values(questionAnswerMap);
            this.questionAnswerCombined = Object.values(questionAnswerMap);
            //Start Join Sub Package with Question
            let subPackageQuestionKey = "subPackageID"
            this.subPackageRawList.sort((a, b) => {
                if (a[subPackageQuestionKey] < b[subPackageQuestionKey]) {
                    return -1;
                }
                if (a[subPackageQuestionKey] > b[subPackageQuestionKey]) {
                    return 1;
                }
                return 0;
            })
            let subPackageRawListMap = this.subPackageRawList.reduce((map, row) => {
                let key = row["subPackageID"]; 
                map[key] = row;
                return map;
            }, {})

            let subPackageQuestionMap = questionAnswerGenerated.reduce((map, row) => { 
                let key = row["subPackageID"];
                if (map[key]) { 
                    if (!map[key].questionList) map[key].questionList = [];
                    map[key].questionList.push(row);
                }
                return map;
            }, subPackageRawListMap)

            let subPackageQuestionGenerated = Object.values(subPackageQuestionMap);
            this.subPackageQuestionAnswerGeneratedList = Object.values(subPackageQuestionMap);
        },
        toPage(param){
            if(this.taskStarted!=1){
                this.$router.push(param);
            }
        },
        getSubPackageTimeSelected() {
            try {
                this.subPackageTimeSelected = this.subPackageQuestionAnswerGeneratedList[this.subPackageOrderSelected-1].subPackageTime;
            }
            catch(err){
                console.log('Exam Finished')
            }
        },
        getTimeSelectedFormatted() {
            let time = this.subPackageTimeSelected
            var hr = ~~(time / 3600);
            var min = ~~((time % 3600) / 60);
            var sec = time % 60;
            var sec_min = "";
            if (hr > 0) {
               sec_min += "" + hr + ":" + (min < 10 ? "0" : "");
            }
            sec_min += "" + min + ":" + (sec < 10 ? "0" : "");
            sec_min += "" + sec;
            this.subPackageTimeFormatted = sec_min;
        },
        getFormatTime(param) {
            let time = param
            var hr = ~~(time / 3600);
            var min = ~~((time % 3600) / 60);
            var sec = time % 60;
            var sec_min = "";
            if (hr > 0) {
               sec_min += "" + hr + ":" + (min < 10 ? "0" : "");
            }
            sec_min += "" + min + ":" + (sec < 10 ? "0" : "");
            sec_min += "" + sec;
            return sec_min.replace(":00", "");
        },
        getSubPackageTotalQuestion(param){
            var newArray = this.questionRawList.filter(function (el) {
            return el.subPackageID == param;
            });
            return newArray.length  
        },
        changeActiveQ(param){
            // console.log(param)
            let questionOrder = param.split(';')[4]-1;
            this.activeQuestion = questionOrder
        },
        activePrevNextQ(param){
            // console.log(param)
            this.activeQuestion = param;
        },
        startTest(){
            this.taskFinished = 0;
            this.subPackageOrderSelected = 1;
            this.getSubPackageTimeSelected();
            this.getTimeSelectedFormatted();
            this.taskStarted = 1;
            this.countDownTimer();
        },
        updateUserPackageStatus(){
            let userPackageID = this.userPackageID;
            let userPackageStatus;
            let userPackageExamGroupID = this.generatedExamGroupID;
            let ref = this;
            if(this.generatedExamGroupID<this.generatedTotalExamGroup){
                userPackageStatus = 'PAID'
            }
            else if(this.generatedExamGroupID==this.generatedTotalExamGroup){
                userPackageStatus = 'COMPLETED'
            }
            axios.put('https://backend-development.uss-utbk.com/userpackage', {
                userPackageID: userPackageID,
                userPackageStatus: userPackageStatus,
                userPackageExamGroupID: userPackageExamGroupID
            })
            .then(function (response) {
                console.log(response);
                if(userPackageStatus == 'PAID'){
                    ref.taskFinished = 0;
                    ref.taskStarted = 0;
                    ref.taskSubmitLoad = 0;
                    ref.refreshExamGroup();
                }
            })
            .catch(function (error) {
                console.log(error);
                if(userPackageStatus == 'PAID'){
                ref.taskFinished = 0;
                ref.taskStarted = 0;
                    ref.taskSubmitLoad = 0;
                    ref.refreshExamGroup();
                }
            });
            clearTimeout(ref.timeOutID);
        },
        updateUserAnswerWeightFactor(){
            let userID = this.userID;
            let userPackageID = this.userPackageID;
            axios.put('https://backend-development.uss-utbk.com/useranswer', {
                userID: userID,
                userPackageID: userPackageID
            })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        countDownTimer() {
            console.log('CountDown');
            if(this.subPackageTimeSelected > 0) {
                this.timeOutID = setTimeout(() => {
                    this.subPackageTimeSelected = this.subPackageTimeSelected - 1;
                    this.getTimeSelectedFormatted()
                    this.countDownTimer();
                }, 1000)
            }
        },
        nextPackage(nextAction){
            this.closeModal();
            this.collectSubPackageDuration();
            this.subPackageOrderSelected = this.subPackageOrderSelected + 1;
            this.getSubPackageTimeSelected();
            this.getTimeSelectedFormatted();
            this.activeQuestion = 0;
            for(let i = 0; i < this.subPackageQuestionAnsweredList.length; i++){
                this.subPackageQuestionAnsweredListAll.push(this.subPackageQuestionAnsweredList[i]);
            };
            this.generateTempAnsweredList();
            this.nextAction = nextAction;
            
        },
        previousPackage(){
            this.subPackageOrderSelected = this.subPackageOrderSelected - 1
            this.getSubPackageTimeSelected();
            this.getTimeSelectedFormatted();
            this.activeQuestion = 0
        },
        calculateAllWeightFactor(){
            let packageID = localStorage.getItem("packageID");
            axios  
            .get("https://backend-development.uss-utbk.com/allweightfactor",{
                params: {
                    packageID: packageID
                }
            })
            .then(res => { 
                let data = res.data.values; 
                console.log(data); 
            });
        },
        collectSubPackageDuration(){
            try {
                this.subPackageNameSelected = this.subPackageRawList[this.subPackageOrderSelected].subPackageName;
            }
            catch(error){
                console.log('A');
            }
            let userPackageID = this.userPackageID;
            let subPackageID = this.subPackageRawList[this.subPackageOrderSelected-1].subPackageID;
            let subPackageTimeUsed = String(this.subPackageRawList[this.subPackageOrderSelected-1].subPackageTime-this.subPackageTimeSelected);
            let subPackageTimeUsedData = userPackageID+';'+subPackageID+';'+subPackageTimeUsed;
            let dArr1 = [];
            for(let d = 0; d < subPackageTimeUsedData.split(";").length; d++){
                dArr1.push(subPackageTimeUsedData.split(";")[d]);
            }
            this.subPackageFinishedList.push(dArr1);
        },
        insertSubPackageExamDuration(){
            let object = this;
            let value = this.subPackageFinishedList;
            axios.post('https://backend-development.uss-utbk.com/n-usersubpackage', {
                arrValue: value
            })
            .then(function (response) {
                object.output = response.data;

            })
            .catch(function (error) {
                object.output = error;
            });
        },
        refreshExamGroup() {
            let userID = localStorage.getItem("userID");
            let packageID = localStorage.getItem("packageID");
            let userPackageID = localStorage.getItem("userPackageID");
            axios
            .get("https://backend-development.uss-utbk.com/n-examgroup",{
                params: {
                    userPackageID: userPackageID
                }
            })
            .then(res => {
                let examGroupData = res.data.values;
                this.examGroupList = examGroupData;
                
                axios
                .get("https://backend-development.uss-utbk.com/n-subpackage",{
                    params: {
                        userPackageID: userPackageID
                    }
                })
                .then(res => {
                    let subPackageData = res.data.values;
                    this.subPackageExamGroupedList = subPackageData;

                    let examGroupSubPackageKey = "subPackageExamGroupID"
                    this.examGroupList.sort((a, b) => {
                        if (a[examGroupSubPackageKey] < b[examGroupSubPackageKey]) {
                            return -1;
                        }
                        if (a[examGroupSubPackageKey] > b[examGroupSubPackageKey]) {
                            return 1;
                        }
                        return 0;
                    })
                    let examGroupListMap = this.examGroupList.reduce((map, row) => {
                        let key = row["subPackageExamGroupID"]; 
                        map[key] = row;
                        return map;
                    }, {})

                    let examGroupSubPackageMap = this.subPackageExamGroupedList.reduce((map, row) => { 
                        let key = row["subPackageExamGroupID"];
                        if (map[key]) { 
                            if (!map[key].subPackageList) map[key].subPackageList = [];
                            map[key].subPackageList.push(row);
                        }
                        return map;
                    }, examGroupListMap)

                    let examGroupSubPackageGenerated = Object.values(examGroupSubPackageMap);
                    this.examGroupSubPackageCombined = Object.values(examGroupSubPackageMap);
                });
            });
        }
    },
    watch: {
        subPackageTimeSelected: function(val) {
            if (val==0 && this.subPackageOrderSelected <= this.subPackageRawList.length) {
                this.collectSubPackageDuration();
                this.subPackageOrderSelected = this.subPackageOrderSelected + 1
                this.getSubPackageTimeSelected();
                this.getTimeSelectedFormatted();
                this.activeQuestion = 0;
                for(let i = 0; i < this.subPackageQuestionAnsweredList.length; i++){
                    this.subPackageQuestionAnsweredListAll.push(this.subPackageQuestionAnsweredList[i]);
                };
                this.generateTempAnsweredList();
                this.countDownTimer();
            }
        },
        subPackageOrderSelected: function(val) {
            if (val>this.subPackageRawList.length) {
                if(this.nextAction=='SUBMIT'){
                    this.taskFinished = 1;
                    this.taskStarted = 0;
                    let questionAnsweredList =  this.subPackageQuestionAnsweredListAll;
                    let arr1 = [];
                    let ref = this;
                    let userPackageID = this.userPackageID;
                    for(let i = 0; i < questionAnsweredList.length; i++){
                        let dString1 = String(questionAnsweredList[i]);
                        if(dString1.includes(";")){
                            let dArr1 = [];
                            for(let d = 0; d < questionAnsweredList[i].split(";").length; d++){
                                if(d==0||d==1||d==2||d==5){
                                    dArr1.push(questionAnsweredList[i].split(";")[d].replace('-',null));
                                    
                                }
                                else if(d==6){
                                    dArr1.push('0');
                                }
                            }
                            arr1.push(dArr1);
                        }
                    };
                    let object = this;
                    axios.post('https://backend-development.uss-utbk.com/useranswer', {
                        userPackageID: userPackageID,
                        arrValue: arr1
                    })
                    .then(function (response) {
                        object.output = response.data;
                        ref.insertSubPackageExamDuration();
                        ref.updateUserPackageStatus();
                        ref.calculateAllWeightFactor();
                        

                    })
                    .catch(function (error) {
                        object.output = error;
                    });
                }
                else {
                    this.taskSubmitLoad = 1;
                    let questionAnsweredList =  this.subPackageQuestionAnsweredListAll;
                    let arr1 = [];
                    let ref = this;
                    let userPackageID = this.userPackageID;
                    for(let i = 0; i < questionAnsweredList.length; i++){
                        let dString1 = String(questionAnsweredList[i]);
                        if(dString1.includes(";")){
                            let dArr1 = [];
                            for(let d = 0; d < questionAnsweredList[i].split(";").length; d++){
                                if(d==0||d==1||d==2||d==5){
                                    dArr1.push(questionAnsweredList[i].split(";")[d].replace('-',null));
                                    
                                }
                                else if(d==6){
                                    dArr1.push('0');
                                }
                            }
                            arr1.push(dArr1);
                        }
                    };
                    let object = this;
                    axios.post('https://backend-development.uss-utbk.com/useranswer', {
                        userPackageID: userPackageID,
                        arrValue: arr1
                    })
                    .then(function (response) {
                        object.output = response.data;
                        ref.insertSubPackageExamDuration();
                        ref.examGroupList = [];
                        ref.subPackageExamGroupedList = [];
                        ref.examGroupSubPackageCombined = [];
                        ref.answerRawList = [];
                        ref.questionRawList = [];
                        ref.subPackageRawList = [];
                        ref.subPackageFinishedList = [];
                        ref.subPackageQuestionAnswerGeneratedList = [];
                        ref.subPackageQuestionAnsweredListAll = [];
                        ref.updateUserPackageStatus();
                        ref.calculateAllWeightFactor();
                        // ref.taskFinished = 0;dipindah ke UpdatePackageStatus
                        // ref.taskStarted = 0;dipindah ke UpdatePackageStatus

                    })
                    .catch(function (error) {
                        object.output = error;
                    });
                }
            }
        }
    },
    data(){
        return {
            timeOutID: null,
            nextAction: null,
            subTestSummary: [
                [],
                [],
                [],
                [],
                []
            ],
            taskSubmitLoad: 0,
            generatedExamGroupID: '',
            generatedCompletedExamGroupID: '',
            generatedTotalExamGroup: '',
            snavDefault: 'snav-default',
            snavAnswered: 'snav-answered',
            snavActive: 'snav-active',
            snavReview: 'snav-review',
            subPackageQuestionAnsweredList: [],
            subPackageQuestionAnsweredListAll: [],
            subPackageFinishedList: [],
            examGroupSubPackageCombined: [],
            examGroupList: [],
            subPackageExamGroupedList: [],
            subPackageQuestionAnswerGeneratedList: [],
            selectedExamGroupDesc: '',
            filterSubPackageList: [
                {
                    key: 'subPackageOrder', label: 'NO', sortable: false
                },
                {
                    key: 'subPackageExamGroupDesc', label: 'GRUP TRY OUT', sortable: false
                },
                {
                    key: 'subPackageDescription', label: 'SUB PAKET', sortable: false
                },
                {
                    key: 'subPackageTime', label: 'DURASI PENGERJAAN', sortable: false
                },
                {
                    key: 'subPackageTotalQuestion', label: 'TOTAL PERTANYAAN', sortable: false
                }
            ],
            display: {
                fullName: '',
                packageDescription: ''
            },
            userID: '',
            userPackageID: '',
            packageGeneratedList:[],
            packageRawList:[],
            subPackageRawList:[],
            questionRawList:[],
            answerRawList:[],
            questionAnswerCombined:[],
            subPackageQuestionCombined:[],
            taskStarted: 0,
            taskInLoad: 0,
            subPackageTimeSelected: 0,
            subPackageTimeFormatted: '',
            subPackageOrderSelected: 1,
            subPackageNameSelected: '',
            taskFinished: 0,
            activeQuestion: 0,
            questionAnsweredList: [],
            questionActiveClass: 'bg-primary',
            questionInactiveClass: 'bg-white text-dark',
            questionAnsweredClass: 'bg-success',
            generatedAnsweredList: [],
            pageLoad: 1,
            tabIndex: 0
        }
    },
    created() {
        let userID = localStorage.getItem("userID");
        let packageID = localStorage.getItem("packageID");
        let userPackageID = localStorage.getItem("userPackageID");
        this.userPackageID = userPackageID;
        this.userID = userID;
    },
    beforeCreate(){
        if(localStorage.getItem("userPackageID") == null){
            this.$router.push("/");
        }
        let userID = localStorage.getItem("userID");
        let packageID = localStorage.getItem("packageID");
        let userPackageID = localStorage.getItem("userPackageID");
        axios
        .get("https://backend-development.uss-utbk.com/n-examgroup",{
            params: {
                userPackageID: userPackageID
            }
        })
        .then(res => {
            let examGroupData = res.data.values;
            this.examGroupList = examGroupData;
            
            axios
            .get("https://backend-development.uss-utbk.com/n-subpackage",{
                params: {
                    userPackageID: userPackageID
                }
            })
            .then(res => {
                let subPackageData = res.data.values;
                this.subPackageExamGroupedList = subPackageData;

                let examGroupSubPackageKey = "subPackageExamGroupID"
                this.examGroupList.sort((a, b) => {
                    if (a[examGroupSubPackageKey] < b[examGroupSubPackageKey]) {
                        return -1;
                    }
                    if (a[examGroupSubPackageKey] > b[examGroupSubPackageKey]) {
                        return 1;
                    }
                    return 0;
                })
                let examGroupListMap = this.examGroupList.reduce((map, row) => {
                    let key = row["subPackageExamGroupID"]; 
                    map[key] = row;
                    return map;
                }, {})

                let examGroupSubPackageMap = this.subPackageExamGroupedList.reduce((map, row) => { 
                    let key = row["subPackageExamGroupID"];
                    if (map[key]) { 
                        if (!map[key].subPackageList) map[key].subPackageList = [];
                        map[key].subPackageList.push(row);
                    }
                    return map;
                }, examGroupListMap)

                let examGroupSubPackageGenerated = Object.values(examGroupSubPackageMap);
                this.examGroupSubPackageCombined = Object.values(examGroupSubPackageMap);
            });
        });

        axios
        .get("https://backend-development.uss-utbk.com/userinformation",{
            params: {
                userID: userID
            }
        })
        .then(res => {
            let userData = res.data.values;
            this.display.fullName = userData[0].userFullName;
        });
        
    }
}
</script>


<style scoped>

.body {
  background-color: #3B73C5;
}

.sbtn-blue {
    background-color: #3B73C5;
    color: #FFF;
    border-radius: 10px;
    padding: 8px 20px;
    font-weight: bold;
    font-size: 14px;
    outline: none;
    border: none;
    text-align: left;
}
.sbtn-bordered-blue {
    background-color: #FFF;
    color: #3B73C5;
    border: 1px solid #3B73C5;
    border-radius: 10px;
    padding: 8px 20px;
    font-weight: bold;
    font-size: 14px;
    outline: none;
    transition: 0.3s;
}
.sbtn-bordered-blue:hover {
    background-color: #3B73C5;
    color: #FFF;
    transition: 0.3s;
}
.sbtn-bordered-orange {
    background-color: #FFF;
    color: #ff8c28;
    border: 1px solid #ff8c28;
    border-radius: 10px;
    padding: 8px 20px;
    font-weight: bold;
    font-size: 14px;
    outline: none;
    transition: 0.3s;
}
.sbtn-bordered-orange:hover {
    background-color: #ff8c28;
    color: #FFF;
    transition: 0.3s;
}
.sbtn-sm-blue {
    outline: none;
    background-color: #3B73C5;
    color: #FFF;
    border-radius: 10px;
    padding: 4px 18px;
    font-size: 12px;
    border:none;
}
.sbtn-sm-blue:hover {
    background-color: #1D3962;
}
.sbtn-sm-yellow {
    outline: none;
    background-color: #fbff13;
    color: #b32828;
    border-radius: 10px;
    padding: 4px 18px;
    font-size: 12px;
    border: 1px solid #b32828;
}

.sbtn-sm-yellow:hover {
    background-color: #fdff85;
}
.snav-default {
    background-color: #F3F7FA;
    color: #3B73C5;
    border: 1px solid #3B73C5;
    
}

.snav-answered {
    background-color: #3B73C5;
    color: #FFF;
    border: 1px solid #3B73C5;
}
.snav-active {
    background-color: #ff8113;
    color: #FFF;
    border: 1px solid #ff8113;
}
.snav-review {
    background-color: #fbff13;
    color: #b32828;
    border: 1px solid #b32828;
}
.sbg-blue {
  background-color: #3B73C5;
}

.sfs-14 {
    font-size: 14px;
}
.sfs-15 {
    font-size: 15px;
}
.sfs-15 p {
    font-size: 15px;
}
.sfs-15 p b {
    font-size: 15px;
}
.sbg-white {
  background-color: #FFFFFF;
}
.sbg-white p {
  background-color: #FFFFFF;
}
.sbg-white p b {
  background-color: #FFFFFF;
}
.sbg-white span {
  background-color: #FFFFFF;
}
.sbg-purple {
  background-color: #733FC0;
}
.sbg-orange {
  background-color: #FF7600;
}
.sbtn-orange {
  background-color: #FF7600;
  color: #FFFFFF;
}
.sbtn-orange:hover {
  background-color: #ff9f4c;
  color: #FFFFFF;
}
.st-purple {
  color: #733FC0;
}
.st-blue {
  color: #3B73C5;
}
.st-blue-title {
  color: #3B73C5;
  font-size:20px;
  font-weight: bold;
}
.st-darkblue-title {
  color: #1D3962;
  font-size:35px;
  font-weight: bold;
}
.st-blue-title-30 {
  color: #3B73C5;
  font-size:30px;
  font-weight: bold;
}
.st-blue-title-25 {
  color: #3B73C5;
  font-size:30px;
  font-weight: bold;
}
.st-white {
  color: #FFFFFF;
}
.st-black {
  color: #000;
}
.st-gray {
  color: #c9c9c9;
}
.st-darkblue {
  color: #1D3962;
}
.srd-25 {
  border-radius: 25px;
}
.sbn-navbar {
    color: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin-left:8px;
    font-weight: bold;
    cursor: pointer;
}
.sbn-navbar-active {
    color: #3B73C5;
    background-color: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    margin-left:8px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}
.sbn-navbar-invert {
    color: #3B73C5;
    background-color: #FFF;
    font-size: 14px;
    padding: 10px;
    margin-left:30px;
    font-weight: bold;
    cursor: pointer;
}
.sbn-navbar:hover {
    background-color: #FFF;
    color: #3B73C5;
    padding: 10px;
    border-radius: 10px;
    margin-left:8px;
    text-decoration: none;
}
.sbn-navbar-invert:hover {
    color: #3B73C5;
    background-color: #FFF;
    padding: 10px;
    font-weight: bold;
    text-decoration: none;
}
/deep/ .stbl tr  {
    border:1px solid #FFF;
    font-size:14px;
    
}
/deep/ .stbl tr td {
    border-bottom: 1px solid #F3F7FA;
    font-size:14px;
    
}
.stbl {
    margin-top:20px;
    font-size:12px;
}
.sbtn-disabled {
    background-color: #FFF;
    color: #3B73C5;
    border-radius: 10px;
    padding: 8px 36px;
    font-weight: bold;
    font-size: 14px;
    float: right;
    border: 1px solid #3B73C5;
}
.stbl-information {
    border: none;
}
.stbl-information tr {
    border: none;
}
.stbl-information tr td {
    border: none;
}
.stbl-information-modal {
    width: 100%;
    border: none;
    font-size: 14px;
}
.stbl-information-modal tr {
    border: none;
    font-size: 14px;
}
.stbl-information-modal tr td {
    border: none;
    color: #1b1b1b;
    font-size: 14px;
}
.par-padding {
    margin-top:20px;
}
</style>